;(function () {
  const arl = {}
  arl._open = XMLHttpRequest.prototype.open
  arl._send = XMLHttpRequest.prototype.send
  arl.callback = function () {
    const event = new CustomEvent('AjaxDetected', {
      detail: {
        url: this.url,
        method: this.method,
        data: this.data,
      },
    })
    document.body.dispatchEvent(event)
  }

  function notNullString(input) {
    return input || ''
  }

  XMLHttpRequest.prototype.open = function (a, b) {
    a = notNullString(a)
    b = notNullString(b)

    arl._open.apply(this, arguments)
    arl.method = a
    arl.url = b

    if (a.toLowerCase() === 'get') {
      arl.data = b.split('?')
      arl.data = notNullString(arl.data[1])
    }
  }

  XMLHttpRequest.prototype.send = function (a, b) {
    a = notNullString(a)
    b = notNullString(b)

    arl._send.apply(this, arguments)

    if (arl.method.toLowerCase() === 'post') {
      arl.data = a
    }

    arl.callback()
  }
})()
